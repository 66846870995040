import React, { useEffect, useState } from 'react'
import TextTransition, { presets } from 'react-text-transition'

// Intro Screen: Rotating Text
const TEXTS = ['Event Managers', 'Content Creators', 'Social Strategists', 'Creative Designers', 'Project Managers'];

const InHousing = ({ data }) => {
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
      const intervalId = setInterval(
        () => setTextIndex((textIndex) => textIndex + 1),
        1500,
      );
      return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="makerlab-section makerlab-section-fading-screen">
      <div className="makerlab-column">
        <div className="makerlab-fading-screen">
          {/* Crane */}
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291 224">
            <path
              d="m77.25 96 18.953 18.777 43.834 6.766h.106c.053 0 .159 0 .212.053.053 0 .106 0 .159.052h.052l40.976 24.389 4.235-3.409-36.052-35.561L77.25 96ZM152.901 202.473l-4.711-25.753-50.928 41.226 57.969-.262-2.33-15.211Z"
              fill="#fff" />
            <path
              d="m66.08 112.94 70.357 69.706 11.171-9.074-9-49.46-43.305-6.661h-.106L66.08 112.94ZM210.236 143.258H224l-14.77-13.165 1.006 13.165ZM157.825 216.111l50.452-50.824-66.546-39.6 13.923 76.315 2.171 14.109ZM184.03 147.662l24.882 14.791-2.541-32.938-22.341 18.147Z"
              fill="#fff" />
          </svg>

          {/* Animated Text */}
          <h2>
            <p>In-housing</p>
            <TextTransition springConfig={presets.wobbly}>{TEXTS[textIndex % TEXTS.length]}</TextTransition>
            <span>&nbsp;since 2015</span>
          </h2>

        </div>
      </div>
    </div>
  );
};

export default InHousing;