import React, { useRef, useEffect, useState } from 'react'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { TextEffectOne } from 'react-text-animate';
import { useInView } from 'react-intersection-observer';

gsap.registerPlugin(ScrollTrigger);

function isMobile() {
  return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 800;
}

const Numbers = ({ data }) => {
  const containerRef = useRef(null);
  const carouselRef = useRef(null);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const container = containerRef.current;
    const carousel = carouselRef.current;
    let scrollWidth = carousel.scrollWidth;
    let containerWidth = container.offsetWidth;
    let endScroll;

    // Function to determine mobile layout
    function isMobile() {
      return window.innerWidth <= 768;
    }

    // Calculate scroll values
    function calculateValues() {
      scrollWidth = carousel.scrollWidth;
      containerWidth = container.offsetWidth;
      endScroll = isMobile() ? scrollWidth - containerWidth + 3000 : scrollWidth - containerWidth;
    }

    calculateValues();  // Initial calculation

    // Create GSAP timeline
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        pin: true,             // Pin the container while scrolling
        scrub: 0.1,            // Smooth scrubbing
        start: 'top top',      // Start when the top of the container hits the top of the viewport
        end: () => `+=${endScroll}`,  // Dynamic scroll distance
        markers: false,        // Disable markers
        invalidateOnRefresh: true,  // Recalculate on resize
        onRefresh: calculateValues  // Recalculate values on refresh
      }
    });

    // Add horizontal scroll animation to the timeline
    tl.to(carousel, {
      x: () => -(scrollWidth - containerWidth) + "px",  // Animate horizontal scroll
      ease: 'none'   // No easing for smooth scrolling
    });

    // You can add more animations or delays as needed
    tl.set({}, {}, "+=0.15");  // Optional: Add a delay or placeholder for future animations

    // Cleanup function to remove ScrollTrigger instance on component unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);


  // useEffect(() => {
  //   const container = containerRef.current;
  //   const carousel = carouselRef.current;
  //   let scrollWidth = carousel.scrollWidth;
  //   let containerWidth = container.offsetWidth;
  //   let endScroll;
  //
  //   // Function to determine mobile layout
  //   function isMobile() {
  //     return window.innerWidth <= 768;
  //   }
  //
  //   // Calculate scroll values
  //   function calculateValues() {
  //     scrollWidth = carousel.scrollWidth;
  //     containerWidth = container.offsetWidth;
  //     endScroll = isMobile() ? scrollWidth - containerWidth + 3000 : scrollWidth - containerWidth;
  //   }
  //
  //   calculateValues();  // Initial calculation
  //
  //   gsap.to(carousel, {
  //     x: () => -(scrollWidth - containerWidth),  // Use function for dynamic recalculation
  //     ease: 'ease',
  //     scrollTrigger: {
  //       trigger: container,
  //       pin: true,
  //       pinSpacing: true,
  //       scrub: 0.1,
  //       start: 'top top',
  //       end: () => `+=${endScroll}`,  // Recalculate endScroll dynamically
  //       markers: false,
  //       invalidateOnRefresh: true,  // Recalculate on resize
  //       onRefresh: calculateValues  // Recalculate values when ScrollTrigger refreshes
  //     },
  //   });
  //
  //   // Cleanup function to remove ScrollTrigger instance on component unmount
  //   return () => {
  //     ScrollTrigger.getAll().forEach(trigger => trigger.kill());
  //   };
  // }, []);



  return (
    <div ref={containerRef} className='makerlab-numbers-wrapper'>
      <div ref={carouselRef} className='makerlab-numbers-scroll'>
        <div className='makerlab-numbers-screen'>

          <h4 ref={ref}>
            <TextEffectOne
              text="The proof is in the pudding"
              staggerDurationBy={0.5}
              wordByWord={true}
              animateOnce={true}
              initialDelay={0.3}
            />

            {inView &&
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 63">
                <path d="m2 2 31 30.122L2 61" stroke="#000" strokeOpacity=".5" strokeWidth="4" strokeLinecap="round" />
              </svg>
            }
          </h4>
        </div>

        <div className="makerlab-numbers-screen">
          <h5 className="blue">
            <VisibilitySensor partialVisibility>
              {({ isVisible }) =>
                <div>{isVisible ? <><CountUp end={98} duration={2} /><small>%</small></> : '0'}</div>
              }
            </VisibilitySensor>
          </h5>
          <h6>
            <TextEffectOne
              text="Client satisfaction in H1 2024"
              staggerDurationBy={0.2}
              wordByWord={true}
              animateOnce={true}
              initialDelay={0.3}
            />
          </h6>
        </div>

        <div className="makerlab-numbers-screen makerlab-numbers-screen--blue">
          <h5>
            <VisibilitySensor partialVisibility >
              {({isVisible}) =>
                <div>{isVisible ? <CountUp end={416} duration={3} /> : '0'}</div>
              }
            </VisibilitySensor>
          </h5>
          <h6>
            <TextEffectOne
              text="Hours of training a year to keep your team at their best"
              staggerDurationBy={0.2}
              wordByWord={true}
              animateOnce={true}
              initialDelay={0.3}
            />
          </h6>
        </div>

        <div className="makerlab-numbers-screen makerlab-numbers-screen--volt">
          <h5>
            <VisibilitySensor partialVisibility >
              {({isVisible}) =>
                <div>{isVisible ? <><CountUp start={4567} end={6000} separator="" duration={3} /><small>+</small></> : '0'}</div>
              }
            </VisibilitySensor>
          </h5>
          <h6>
            <TextEffectOne
              text="Talented people in our network, ready to support you"
              staggerDurationBy={0.2}
              wordByWord={true}
              animateOnce={true}
              initialDelay={0.3}
            />
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Numbers;