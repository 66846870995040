import React from 'react';
import EmailRestAPI from '../EmailRestAPI';

const TalkToUs = () => {
    return (
        <div id="contact" className='makerlab-contact-wrapper'>
            <div className='makerlab-contact-content'>
                <h3>Talk to us</h3>

                {/* FORM */}
                <EmailRestAPI />
            </div>
        </div>
    );
};

export default TalkToUs;