import axios from 'axios';
import React, { useState } from 'react';

const EmailRestAPI = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [region, setRegion] = useState('N/A');

  const [wasSentSuccessfully, setWasSentSuccessfully] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [submitText, setSubmitText] = useState("Submit");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // set button text
    setSubmitText("Sending...");
    setIsSending(true);

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_tp8azya';
    const templateIdAPAC = 'template_czkn2a7'; // APAC
    const templateIdEMEA = 'template_f7vli7h'; // EMEA
    const publicKey = '7-VBs0Z92r9ZNHlwh';

    // Data for APAC and EMEA
    const dataAPAC = {
      service_id: serviceId,
      template_id: templateIdAPAC,
      user_id: publicKey,
      template_params: {
        from_email: email,
        region: region,
        to_name: 'Web Wizard',
        message: message,
      }
    };

    const dataEMEA = {
      service_id: serviceId,
      template_id: templateIdEMEA,
      user_id: publicKey,
      template_params: {
        from_email: email,
        region: region,
        to_name: 'Web Wizard',
        message: message,
      }
    };

    try {
      if (region === 'APAC') {
        // Send the email for APAC
        await axios.post("https://api.emailjs.com/api/v1.0/email/send", dataAPAC);
      } else if (region === 'EMEA') {
        // Send the email for EMEA
        await axios.post("https://api.emailjs.com/api/v1.0/email/send", dataEMEA);
      } else if (region === 'Global') {
        // Send two requests, one for APAC and one for EMEA
        await Promise.all([
          axios.post("https://api.emailjs.com/api/v1.0/email/send", dataAPAC),
          axios.post("https://api.emailjs.com/api/v1.0/email/send", dataEMEA)
        ]);
      }

      // Clear the form and set success
      setEmail('');
      setMessage('');
      setRegion('');
      setWasSentSuccessfully(true);

    } catch (error) {
      console.error(error);
      setIsSending(false);
      setSubmitText("Send");
    }
  };


  return (
    <form onSubmit={handleSubmit} className='makerlab-emailForm' data-ty={wasSentSuccessfully}>
      {/* input group */}
      <div className='makerlab-emailForm-group'>
        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={isSending}
        />
      </div>

      <h4>Select your region</h4>

      <div className='makerlab-emailForm-group'>
        <fieldset className='fieldset-region'>
          <label>
            <input type="radio" name="region" value="EMEA" onChange={(e) => setRegion(e.target.value)} required />
            <span className='checkmark'></span>
            EMEA
          </label>

          <label>
            <input type="radio" name="region" value="Global" onChange={(e) => setRegion(e.target.value)} />
            <span className='checkmark'></span>
            Global
          </label>

          <label>
            <input type="radio" name="region" value="APAC" onChange={(e) => setRegion(e.target.value)} />
            <span className='checkmark'></span>
            APAC
          </label>
        </fieldset>
      </div>

      {/* group */}
      <textarea
        cols="30"
        rows="1"
        placeholder="Message (Optional)"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={isSending}
      >
      </textarea>
      <button type="submit">{!wasSentSuccessfully ? submitText : "Thank you for your submission"}</button>
    </form>
  )
}

export default EmailRestAPI