import React from 'react';

const Cases = ({ data }) => {
    return (
        <div id="work" className='makerlab-cases'>
            <h3>{data.title}</h3>

            <div className='makerlab-cases-wrapper'>
            {data.cases.map((item, index) => {
                return (
                    <a key={index} href={'case/' + item.fields.slug} className='makerlab-cases-item'>
                        <img src={item.fields.caseLogo.fields.file.url} alt={item.fields.caseLogo.fields.file.fileName} />
                        <h4>{item.fields.caseDescription}</h4>
                    </a>
                )
            })}
            </div>

        </div>
    );
};

export default Cases;