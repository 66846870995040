import React from 'react';
import Slider from 'react-infinite-logo-slider';

const Logos = ({ logos }) => {
  // console.log("Data: ", logos)
    return (
        <div className='makerlab-logos-wrapper'>
            <Slider
                width="170px"
                duration={20}
                pauseOnHover={false}
            >
                {logos.map((item, index) => {
                    return ( 
                        <Slider.Slide key={index} className="makerlab-logos-item">
                            <img src={item.fields.file.url} alt="Client Logo" />
                        </Slider.Slide>
                    )
                })}
            </Slider>
        </div>
    );
};

export default Logos;