import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function isMobile() {
  return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 800;
}

const Talent = ({ data }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const prevScrollY = useRef(0);
  const containerRef = useRef(null);
  
  const { ref } = useInView({
    threshold: Array.from({ length: 101 }, (_, i) => i / 100),
  });

  useEffect(() => {
    const endScroll = isMobile() ? 2500 : 1500;

    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: containerRef.current,
        pin: true, // pin the trigger element while active
        start: 'top top', // when the top of the trigger hits the top of the viewport
        end: `+=${endScroll}`, // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        markers: false,
        onUpdate: (self) => {
          const items = Array.from(document.querySelectorAll('.benefit-item'));
          const totalItems = items.length;
          const progress = self.progress.toFixed(3);

          // Calculate the index of the element to highlight
          const indexToHighlight = Math.min(
            Math.floor(progress * totalItems),
            totalItems - 1 // ensure the index is within bounds
          );

          // Update the currentItemIndex state
          setCurrentItemIndex(indexToHighlight);

          if (progress === 1) {
            setCurrentItemIndex(totalItems - 1);
          }

          if (progress === 0) {
            setCurrentItemIndex(0);
          }

          // console.log(
          //   'progress:', progress,
          //   'direction:', self.direction,
          //   'velocity', self.getVelocity(),
          //   'highlight index:', indexToHighlight
          // );
        }
      }
    });
  }, []);

  return (
    <div ref={containerRef}>
      <div ref={ref} id="intro" className='makerlab-talent-wrapper'>
        <h3>{data.title}</h3>

        <ul>
          {data.benefitsList.map((item, index) => {
            const isHighlighted = index === currentItemIndex;
            return (
              <li
                key={index}
                className={`benefit-item ${isHighlighted ? 'highlight' : ''}`}
              >
                {item.fields.title}
              </li>
            );
          })}
        </ul>

        <h4>{documentToReactComponents(data[`description${currentItemIndex + 1}`])}</h4>

        <div className="makerlab-scrolltobottom">
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 36">
            <path d="M61 2 30.878 33 2 2" stroke="#181917" strokeOpacity=".15" strokeWidth="4"
                  strokeLinecap="round" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Talent;
