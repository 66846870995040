import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Content from './Content';
import CasePage from './Pages/CasePage';

const AppRouter = ({ data }) => {
    // console.log("router data: ", data);

    return (
        <Router>
            <Routes>
                <Route 
                    path="/" 
                    element={(
                        <Content
                            menuList={data.MenuList}
                            introScreen={data.introScreen}
                            clientsScreen={data.clientsScreen}
                            talentOnYourTermsScreen={data.talentOnYourTermsScreen}
                            microAgenciesScreen={data.microAgenciesScreen}
                            casesScreen={data.casesScreen}
                            aboutScreen={data.aboutScreen}
                            championsScreen={data.championsScreen}
                            insightsScreen={data.insightsScreen}
                            cookieBanner={data.cookieBanner}
                            footerData={data.footerData}
                        />
                    )}
                />
                <Route path="/case/:slug" element={<CasePage data={data.casesScreen} menuList={data.MenuList} />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
