import { useEffect } from 'react';

const SoproPlugin = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = `
      (function (o, u, t, b, a, s, e) {
        window[b] = window[b] || {}; (e = e || [])['key'] = o; e['__obr'] = u.location.href; a = [];
        u.location.search.replace('?', '').split('&').forEach(function (q) { if (q.startsWith(b) || q.startsWith('_obid')) e[q.split('=')[0]] = q.split('=')[1]; });
        e['_obid'] = e['_obid'] || (u.cookie.match(/(^|;)\s*_obid\s*=\s*([^;]+)/) || []).pop() || 0;
        for (let k in e) { if (e.hasOwnProperty(k)) a.push(encodeURIComponent(k) + '=' + encodeURIComponent(e[k])); }
        s = u.createElement('script'); s.src = t + '?' + a.join('&'); u.body.appendChild(s);
      })('6325cba9-7386-4372-bb0b-9279ea1649e7', document, 'https://plugin.sopro.io/hq.js', 'outbase');
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup script when component unmounts
    };
  }, []);

  return null;
};

export default SoproPlugin;