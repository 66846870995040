import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';

import Header from "./components/Header/Header";
import Logos from "./components/Logos/Logos";
import Talent from "./components/Talent/Talent";
import Map from "./components/Map/Map";
import Cases from "./components/Cases/Cases";
import Champions from "./components/Champions/Champions";
import TalkToUs from "./components/TalkToUs/TalkToUs";
import Footer from "./components/Footer/Footer";
import Numbers from './components/Numbers/Numbers'
import InHousing from './components/InHousing/InHousing'

import CookieBar from './components/CookieBar/CookieBar';

import ReactGA from 'react-ga4';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

import SoproPlugin from './components/SoproPlugin/SoproPlugin'

const Content = ({ 
  menuList,
  introScreen,
  clientsScreen,
  talentOnYourTermsScreen,
  casesScreen,
  championsScreen,
  cookieBanner,
  footerData
}) => {
  const videoRef = useRef(null);
  const [showCookieBanner, setShowCookieBanner] = useState(null);
  const [userAcceptedCookies, setUserAcceptedCookies] = useState(false);
  const [showBottomArrow, setShowBottomArrow] = useState(false);
  const [playCount, setPlayCount] = useState(0);
  const { hash } = useLocation();

  useEffect(() => {
    setShowCookieBanner(!localStorage.getItem('showCookieBanner'));
    setUserAcceptedCookies(document.cookie.includes('cookie-consent=true'));
    // console.log("COOKIE: ", !userAcceptedCookies);
  }, []);

  // handle Analytics
  useEffect(() => {
    if (showCookieBanner === false && userAcceptedCookies) {
      // console.log("here");
      ReactGA.initialize("G-E27XB1EFSM");

      const analytics = Analytics({
        app: 'awesome-app',
        plugins: [
          googleTagManager({
            containerId: 'GTM-PKWX985H'
          })
        ]
      })

      /* Track a page view */
      analytics.page();
    }
  }, [showCookieBanner]);

  useEffect(() => {
    if (!videoRef.current) {
        return;
    }

    if (!videoRef.current.muted) {
        //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
        videoRef.current.defaultMuted = true;
        videoRef.current.muted = true;
        if (showCookieBanner && userAcceptedCookies) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
    }

    if (showCookieBanner === false && userAcceptedCookies) {
        videoRef.current.play();
    }
  }, [showCookieBanner, userAcceptedCookies]);

  const handleTimeUpdate = () => {
    if (videoRef.current.currentTime >= videoRef.current.duration - 0.1) {
      setPlayCount(prevCount => {
        const newCount = prevCount + 1;
        if (newCount === 2) {
          setShowBottomArrow(true);
        }
        return newCount;
      });
    }
  };

  const toggleCookieBannerState = () => {
    setShowCookieBanner(false);
    localStorage.setItem('showCookieBanner', 'false');
  }

  const toggleCookieConsent = () => {
    document.cookie = "cookie-consent=true; max-age=31536000; path=/";
    setUserAcceptedCookies(true);
  }

  // scroll if hash
  useEffect(() => {
    setTimeout(() => {
      const handleHashChange = () => {
        const hash = window.location.hash;
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'instant' });
        }
      };

      window.addEventListener('hashchange', handleHashChange);

      // Call the function on mount
      handleHashChange();

      return () => {
        window.removeEventListener('hashchange', handleHashChange);
      };
    }, 100);

  }, []);

  // useEffect(() => {
  //   if (hash) {
  //     const scrollToElement = () => {
  //       console.log("here")
  //       const element = document.getElementById(hash.slice(1));
  //       if (element) {
  //         element.scrollIntoView();
  //       } else {
  //         // Try again after a short delay
  //         setTimeout(scrollToElement, 10);
  //       }
  //     };
  //
  //     scrollToElement(); // Initial call
  //   }
  // }, [hash]);

  // mobile
  function isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 800;
  }

  if (isMobile()) {
    // In your React component
    useEffect(() => {
      const setDynamicVh = () => {
        const vh = window.visualViewport.height * 0.01; // Calculate 1% of the visual viewport height
        document.documentElement.style.setProperty('--vh', `${vh}px`); // Set CSS variable
      };

      setDynamicVh(); // Set initial value

      // Listen to resize events on the visual viewport
      window.visualViewport.addEventListener('resize', setDynamicVh);

      return () => {
        window.visualViewport.removeEventListener('resize', setDynamicVh); // Cleanup on unmount
      };
    }, []);
  }

  return (
    <div className="home-content">
      <SoproPlugin />
      {/* Cookie Banner */}
      {!userAcceptedCookies &&
        <CookieBar
          data={cookieBanner}
          showCookieBanner={!userAcceptedCookies}
          toggleCookieBannerState={toggleCookieBannerState}
          toggleCookieConsent={toggleCookieConsent}
        />
      }

      {/* Header */}
      <Header menuList={menuList} caseProp={false} />

      {/* Intro Screen */}
      <div id="reel" className="makerlab-section makerlab-section-intro">
        <div className="makerlab-column">
          {introScreen.fields.introHeroMedia?.fields?.file?.contentType?.includes('video')
            ?
            <video loop autoPlay playsInline ref={videoRef} onTimeUpdate={handleTimeUpdate}>
                <source src={introScreen.fields.introHeroMedia?.fields?.file?.url} type={introScreen.fields.introHeroMedia?.fields?.file?.contentType} />
                Your browser does not support the video tag.
              </video>
              : "img"
            }
            <div className="makerlab-scrolltobottom" data-show={showBottomArrow}>
              <span>SCROLL</span>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 36">
                <path d="M61 2 30.878 33 2 2" stroke="#fff" strokeOpacity=".5" strokeWidth="4"
                      strokeLinecap="round" />
              </svg>
            </div>
          </div>
      </div>

      {/* Logos */}
      <Logos logos={clientsScreen?.fields?.newClientLogos} />

      {/* In-Housing Screen */}
      <InHousing />

      {/* Talent */}
      <Talent data={talentOnYourTermsScreen?.fields} />

      {/* The proof is in the pudding */}
      <Numbers />

      {/* Map */}
      <Map />

      {/* Cases */}
      <Cases data={casesScreen.fields} />

      {/* Champions */}
      <div className="makerlab-carousel-container">
        <Champions data={championsScreen} />
      </div>

      {/* Talk to us */}
      <TalkToUs />

      {/* Footer */}
      <Footer menuList={menuList} footerData={footerData} />
    </div>
  )
}

export default Content
