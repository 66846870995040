import useSWR from 'swr'
import { Spinner } from '@contentful/f36-spinner'
import { createClient } from 'contentful'
// import ReactGA from 'react-ga4';
// import Analytics from 'analytics';
// import googleTagManager from '@analytics/google-tag-manager';
import { ReactLenis, useLenis } from '@studio-freight/react-lenis';
import AppRouter from './Router';

const client = createClient({
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_DELIVERY_TOKEN,
});

const fetcher = async () => {
  try {
    const MenuListData = await client.getEntries({ content_type: 'menuList' });
    const introScreenData = await client.getEntries({ content_type: 'introScreen' });
    const clientsScreenData = await client.getEntries({ content_type: 'clientsScreen' });
    const talentOnYourTermsScreenData = await client.getEntries({ content_type: 'talentOnYourTerms' });
    const microAgenciesScreenData = await client.getEntries({ content_type: 'microAgenciesScreen' });
    const casesScreenData = await client.getEntries({ content_type: 'casesScreen' });
    const aboutScreenData = await client.getEntries({ content_type: 'aboutScreen' });
    const championsScreenData = await client.getEntries({ content_type: 'championsScreen' });
    const insightsScreenData = await client.getEntries({ content_type: 'insightsScreen' });
    const cookieBannerData = await client.getEntries({ content_type: 'cookieBanner' });
    const footerData = await client.getEntries({ content_type: 'footer' });

    return {
      MenuList: MenuListData.items[0],
      introScreen: introScreenData.items[0], 
      clientsScreen: clientsScreenData.items[0],
      talentOnYourTermsScreen: talentOnYourTermsScreenData.items[0],
      microAgenciesScreen: microAgenciesScreenData.items[0],
      casesScreen: casesScreenData.items[0],
      aboutScreen: aboutScreenData.items[0],
      championsScreen: championsScreenData.items[0],
      insightsScreen: insightsScreenData.items[0],
      cookieBanner: cookieBannerData.items[0],
      footerData: footerData.items[0],
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

function App() {
  const { data, error } = useSWR('contentful', fetcher);

  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  })

  if (error) {
    console.error(error);
    return <div>Failed to load</div>;
  }

  if (!data) return <div className='makerlab-spinner-container'><Spinner size="large" /></div>;

  return (
    <ReactLenis
      root
      options={{
        duration: 1,
        easing: (t) => 1 - Math.pow(1 - t, 5),
        lerp: 0.15,
        smooth: true
      }}
    >
      <main>
        <AppRouter data={data} />
      </main>
    </ReactLenis>
  );
}

export default App;
