import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Header from '../components/Header/Header';

const CasePage = ({ data, menuList }) => {
    const { slug } = useParams();
    if (!data || !data.fields || !Array.isArray(data.fields.cases)) {
        return <div>Invalid data</div>;
    }

    const caseData = data.fields.cases.find((caseItem) => caseItem.fields.slug === slug);

    if (!caseData) {
        return <div>Case not found</div>;
    }

    return (
        <div>
            <Header menuList={menuList} isfaded={false} caseProp={true} />
            <div className='makerlab-case-wrapper'>
                {/* Left: Text */}
                <div className='makerlab-case-left'>
                    {/* Logo */}
                    <img src={caseData.fields.caseLogo.fields.file.url} alt={caseData.fields.caseLogo.fields.file.fileName} />
                    
                    <h2>{caseData.fields.caseDescription}</h2>
                    <div className='makerlab-case-text-wrapper'>{documentToReactComponents(caseData.fields.caseText)}</div>
                </div>

                {/* Right: Gallery */}
                <div className='makerlab-case-right'>
                    {caseData.fields.caseGallery.map((item, index) => {
                        return (
                            <img key={index} src={item.fields.file.url} alt={item.fields.file.fileName} />
                        )
                    })}

                </div>
            </div>
        </div>
    );
};

export default CasePage;