import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const mockData = [
    {
        title: 'Expert Talent, Pre-Vetted for Quality',
        text: 'We\'ve handpicked the best talent, so you get Makers you can trust every time.',
    },
    {
        title: 'Quick to Respond, Ready to Scale',
        text: 'Whether you need help now or later, our global network is ready to jump in and deliver.',
    },
    {
        title: 'AI-Powered Precision',
        text: 'We use AI to match you with the perfect fit, tailored to your needs, without the hassle.',
    }
]

const Map = ({ data }) => {
    const [showMapPins, setShowMapPins] = useState(false);

    const { ref, inView } = useInView({
        threshold: 0.8,
    });

    useEffect(() => {
        if (inView) {
            setShowMapPins(inView);
        }
    }, [inView]);

    useEffect(() => {
        if (showMapPins) {
          const gElements = document.querySelectorAll('.makerlab-map-svg .g-group');
          gElements.forEach((gElement) => {
            const delay = Math.random() * 1; // Random delay between 0 and 2 seconds
            gElement.style.transition = `opacity 0.5s ease ${delay}s`;
            gElement.style.opacity = 1;
          });
        } else {
          const gElements = document.querySelectorAll('.makerlab-map-svg .g-group');
          gElements.forEach((gElement) => {
            gElement.style.transition = 'opacity 0.5s ease';
            gElement.style.opacity = 0;
          });
        }
    }, [showMapPins]);

    return (
        <div ref={ref} className='makerlab-section makerlab-map-wrapper'>
            <h3>Global talent on tap</h3>

            {/* map */}
            <div className='makerlab-map-svg' data-active={showMapPins}>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1029 504">
                    <g id="m-g-map" stroke="#D5FD00" strokeWidth="2" strokeMiterlimit="10">
                        <path
                          d="m504.473 363.452 42.499-20.838-23.812 85.322-18.687-64.484ZM495.457 300.199l51.514 42.415-42.499 20.838-9.015-63.253ZM598.489 309.058l-103.032-8.859 51.514 42.415 51.518-33.556ZM610.047 252.941l-11.558 56.117 39.591-52.177-28.033-3.94ZM610.046 252.942l-59.017-5.415 47.46 61.531 11.557-56.116Z" />
                        <path d="m570.212 179.352 39.834 73.59-59.017-5.415 19.183-68.175Z" />
                        <path
                          d="m504.473 170.984 65.739 8.368-19.182 68.175-46.557-76.543ZM478.243 242.196l72.787 5.331-55.573 52.672-17.214-58.003Z" />
                        <path d="m437.259 278.294 40.984-36.098 17.214 58.003-58.198-21.905Z" />
                        <path
                          d="m504.472 170.984-26.229 71.213 72.787 5.33-46.558-76.543ZM450.21 162.369l54.262 8.615-26.229 71.212-28.033-79.827Z" />
                        <path
                          d="m410.866 211.593 39.344-49.224 28.033 79.827-67.377-30.603ZM413.078 252.941l65.165-10.745-67.377-30.603 2.212 41.348Z" />
                        <path
                          d="m437.259 278.294-24.181-25.353 65.165-10.745-40.984 36.098ZM495.458 154.741l9.014 16.243-54.262-8.615 45.248-7.628ZM570.212 179.351l11.638-22.152 40.165 26.584-51.803-4.432Z" />
                        <path d="m613.983 245.641-43.771-66.289 51.803 4.431-8.032 61.858Z" />
                        <path
                          d="m638.08 205.603-16.065-21.821-8.033 61.859 24.098-40.038ZM653.981 225.622l-15.901-20.018-24.098 40.037 39.999-20.019Z" />
                        <path
                          d="m649.612 196.472-11.532 9.132 15.901 20.018-4.369-29.15ZM662.509 211.047l-12.897-14.575 4.37 29.15 8.527-14.575ZM581.849 157.199l-22.514-17.939-8.306 14.44 30.82 3.499Z" />
                        <path
                          d="m596.114 137.73-36.779 1.53 22.515 17.94 14.264-19.47ZM539.145 156.136l6.231-16.876-17.134 3.174 10.903 13.702ZM559.335 139.26l1.204-20.783-15.163 20.783h13.959ZM622.016 183.783l44.098 13.945-8.524-56.444-35.574 42.499ZM596.114 137.73l25.901 46.053L581.85 157.2l14.264-19.47Z" />
                        <path
                          d="m657.59 141.284-61.477-3.554 25.902 46.053 35.575-42.499ZM666.113 197.728l61.313-43.482-21.311 64.32-40.002-20.838ZM735.295 233.99l-29.18-15.424 18.197 46.107 10.983-30.683Z" />
                        <path d="m766.115 208.557-60 10.009 29.18 15.424 30.82-25.433Z" />
                        <path
                          d="m727.426 154.246 38.689 54.311-60 10.009 21.311-64.32ZM657.59 141.284l69.836 12.962-61.312 43.482-8.524-56.444ZM528.242 142.435l-17.869-20.565 29.292-15.533-11.423 36.098Z" />
                        <path d="m545.376 139.26-5.711-32.923-11.423 36.098 17.134-3.175Z" />
                        <path
                          d="m560.539 118.477-20.874-12.14 5.711 32.923 15.163-20.783ZM596.114 137.73l-35.575-19.253 33.523-39.051 2.052 58.304ZM539.665 106.337l-28.47-13.785-.822 29.318 29.292-15.533Z" />
                        <path d="m476.821 102.397 17.378-17.062 16.174 36.535-33.552-19.473Z" />
                        <path d="m511.195 92.552-16.996-7.217 16.174 36.535.822-29.318Z" />
                        <path d="m491.795 129.527 18.578-7.657-33.552-19.473 14.974 27.13Z" />
                        <path
                          d="m517.586 149.654-25.792-20.128 18.579-7.657 7.213 27.785ZM507.313 151.73l10.274-1.53-3.169 5.141-7.105-3.611ZM457.26 112.898l19.561-10.501 14.973 27.13-34.534-16.629Z" />
                        <path
                          d="m463.379 130.946-6.119-18.049 34.534 16.629-28.415 1.42ZM440.864 132.014l22.515-1.067-2.678 24.395-19.837-23.328Z" />
                        <path d="m491.794 129.526-31.093 25.816 2.678-24.395 28.415-1.421Z" />
                        <path
                          d="m438.652 154.741 22.05.601-19.838-23.328-2.212 22.727ZM457.998 105.598l16.527-8.368-15.545-3.12-.982 11.488Z" />
                        <path d="m457.998 75.242 8.264 3.364-7.282 15.505-.982-18.869Z" />
                        <path
                          d="m474.525 97.23-8.263-18.624-7.282 15.504 15.545 3.12ZM444.036 89.435l9.288-.82-2.241 9.515-7.047-8.695Z" />
                        <path
                          d="m441.468 99.335 9.615-1.205-7.047-8.695-2.568 9.9ZM416.192 49.317l-.902 7.22 10.575 1.723-9.673-8.943Z" />
                        <path
                          d="m437.67 51.695-11.805 6.565-9.673-8.943 21.478 2.378ZM511.195 92.552l38.089-21.002-9.619 34.787-28.47-13.785ZM529.609 66.08l19.674 5.47 12.02-20.893-31.694 15.424Z" />
                        <path d="m594.062 79.426-54.397 26.911 20.874 12.14 33.523-39.051Z" />
                        <path
                          d="m549.283 71.55 44.779 7.876-54.397 26.911 9.618-34.787ZM535.375 50.137l-5.766 15.944 31.694-15.424-25.928-.52ZM525.019 37.53l10.356 12.606 14.727-14.357-25.083 1.752Z" />
                        <path
                          d="m561.303 50.657-11.201-14.878-14.726 14.357 25.927.52ZM500.539 56.563l34.836-6.426-10.356-12.606-24.48 19.032Z" />
                        <path
                          d="m517.956 68.16-17.417-11.597 34.836-6.426-17.419 18.023ZM500.539 71.55V56.564l-13.772 8.862 13.772 6.124Z" />
                        <path d="m489.826 76.581 10.713-5.032-13.772-6.124 3.059 11.156Z" />
                        <path
                          d="m517.956 68.16-17.417 3.39V56.564l17.417 11.596ZM509.246 85.335l-8.707-13.785 17.417-3.39-8.238 17.175M561.303 50.657l32.759 28.769-44.779-7.876 12.02-20.893Z" />
                        <path
                          d="m622.288 38.296-60.985 12.361 32.759 28.769 28.226-41.13ZM633.984 98.953l-39.922-19.527 2.052 58.304 37.87-38.777Z" />
                        <path
                          d="m657.59 141.284-23.607-42.332-37.87 38.778 61.477 3.554ZM766.115 208.557l31.474-43.81-70.163-10.501 38.689 54.311ZM788.739 237.711l-22.624-29.154 49.29 12.088-26.666 17.066ZM794.202 264.455l-5.464-26.744 16.64 22.535-11.176 4.209Z" />
                        <path d="m805.378 260.245-16.64-22.535 26.666-17.065-10.026 39.6Z" />
                        <path
                          d="m831.8 253.68-16.396-33.035-10.026 39.6 26.422-6.565ZM797.59 164.747l17.815 55.898-49.29-12.088 31.475-43.81Z" />
                        <path
                          d="m860.215 183.455-62.625-18.708 17.815 55.898 44.81-37.19ZM838.985 143.173l21.23 40.281-62.625-18.707 41.395-21.574ZM736.113 78.606l-8.687 75.641 70.163 10.501-61.476-86.142Z" />
                        <path
                          d="m657.59 141.284 78.523-62.678-8.687 75.641-69.836-12.963ZM633.984 98.953l-11.696-60.657-28.226 41.13 39.922 19.527ZM700.211 15.76l-77.923 22.535 11.696 60.657 66.227-83.191Z" />
                        <path d="m736.113 78.606-102.13 20.347 23.607 42.331 78.523-62.678Z" />
                        <path d="m838.985 143.174 12.703-54.23-115.575-10.338 102.872 64.568Z" />
                        <path
                          d="m797.59 164.748 41.395-21.574-102.872-64.568 61.477 86.142ZM700.211 15.76l35.902 62.846-102.13 20.346 66.228-83.191Z" />
                        <path d="m817.102 28.56-116.891-12.8 35.902 62.846 80.989-50.047Z" />
                        <path
                          d="M851.688 88.943 817.103 28.56l-80.99 50.046 115.575 10.337ZM882.836 109.454l-31.147-20.51-12.704 54.23 43.851-33.72Z" />
                        <path
                          d="m867.263 137.675 15.573-28.222-43.851 33.72 28.278-5.498ZM869.886 161.631l-30.901-18.457 28.278-5.498 2.623 23.955ZM882.836 109.453l-6.72-17.393 21.638 22.971-14.918-5.578ZM851.688 88.943l22.708-52.096-57.293-8.287 34.585 60.383Z" />
                        <path d="m904.15 62.2-52.462 26.743 22.708-52.096 29.754 25.352Z" />
                        <path d="m951.61 48.906-77.214-12.06L904.151 62.2l47.459-13.293Z" />
                        <path
                          d="m924.315 100.592 27.295-51.686L904.15 62.2l20.165 38.392ZM894.641 121.677l5.245 17.036 12.023-9.077-17.268-7.959ZM911.143 163.314l-11.256-24.601-3.496 19.144 14.752 5.457ZM884.04 165.405l12.351-7.548 14.751 5.457-27.102 2.091ZM889.722 172.845l-5.682-7.439-.546 10.173 6.228-2.734ZM863.821 198.441l-5.792 9.515 6.447 3.502-.655-13.017ZM597.15 30.092l9.51-1.31 2.732 6.233-12.242-4.923Z" />
                        <path
                          d="M614.856 16.639 597.15 30.093l9.51-1.311 8.196-12.143ZM502.697 8.544l10.739 8.094-.549-8.094h-10.19Z" />
                        <path
                          d="m521.304 10.183-8.417-1.639.549 8.094 7.868-6.455ZM531.604 6.205l-13.237.411 10.861 3.075 2.376-3.486ZM581.359 4.604l-9.128 1.424 6.286 1.639 2.842-3.063ZM594.637 2.69c-.655.327-5.409 3.444-5.409 3.444l6.337-.546-.928-2.899ZM604.747 3.676l-5.737 2.898 8.087-.765-2.35-2.133ZM676.169 4.168l-8.306 1.697 8.633 2.953-.327-4.65Z" />
                        <path
                          d="m687.043 7.886-10.874-3.718.327 4.65 10.547-.932ZM693.3 9.062l-1.474 3.284 10.274-.987-8.8-2.297ZM795.735 17.73l-12.132 1.424 9.178 2.79 2.954-4.213ZM806.852 18.387l-2.006 3.036h8.893l-6.887-3.036ZM815.405 290.871l-10.026-30.626-11.176 4.21 21.202 26.416ZM785.625 274.027l32.965 30.192-10.836 7.712-22.129-37.904Z" />
                        <path
                          d="m819.803 316.934-1.214-12.715-10.835 7.712 12.049 5.003ZM831.8 290.871l27.102-21.33 1.641 28.222-28.743-6.892Z" />
                        <path
                          d="m836.936 306.626 23.607-8.862-28.743-6.893 5.136 15.755ZM854.311 310.575l6.232-12.811-23.606 8.862 17.374 3.949ZM819.803 316.934l10.138 11.021 11.038-7.548-21.176-3.473Z" />
                        <path
                          d="m848.738 327.3-7.759-6.893-11.038 7.548 18.797-.655ZM863.166 326.313l-14.428.987 11.805 3.718 2.623-4.705ZM869.503 325.33l-3.714 4.814 11.256-1.479-7.542-3.335ZM891.035 327.736l-10.931 2.407v5.797l10.931-8.204ZM598.489 309.058l2.052 45.04-53.569-11.484 51.517-33.556ZM546.972 342.614l-23.812 85.322 36.288-6.455-12.476-78.867Z" />
                        <path
                          d="m559.447 421.481 41.094-67.383-53.569-11.484 12.475 78.867ZM630.866 342.614l-18.36 15.916 8.524 31.338 9.836-47.254Z" />
                        <path
                          d="m609.229 389.868 3.277-31.339 8.524 31.339h-11.801ZM734.393 258.439v15.588l6.639-5.006-6.639-10.582ZM865.378 224.803l7.214 6.317-8.608 6.07 1.394-12.387ZM828.493 218.322l-3.608 6.481 6.395.572-2.787-7.053Z" />
                        <path
                          d="m833.001 219.225-4.508-.903 2.787 7.053 1.721-6.15ZM873.41 243.919l-.818-12.799-8.608 6.07 9.426 6.729Z" />
                        <path
                          d="m884.64 248.678-11.23-4.759 9.59 12.879 1.64-8.12ZM883.575 272.961l6.475-6.482-7.05-9.681.575 16.163ZM873.41 250.728l2.543 9.598 2.132-4.801-4.675-4.797ZM866.28 316.933l11.311-6.359-10.983-8.245-.328 14.604Z" />
                        <path
                          d="m872.592 290.871-5.985 11.459 10.983 8.245-4.998-19.704ZM896.391 286.606l-3.172 8.75 5.904-.874-2.732-7.876ZM885.568 289.287l-12.976 1.584 9.262 3.066 3.714-4.65ZM903.383 306.625l-8.087.546 9.837 3.403-1.75-3.949ZM928.739 301.484l-9.754 10.199 35.636.678-25.882-10.877ZM916.115 298.31l2.87 13.373-12.048-10.96 9.178-2.413ZM937.837 330.144l-18.852-18.46 30.191 12.496-11.339 5.964Z" />
                        <path d="m918.985 311.684 35.636.678-5.445 11.818-30.191-12.496Z" />
                        <path
                          d="m968.849 335.503-14.228-23.142-5.445 11.819 19.673 11.323ZM976.719 312.361l-13.333 4.573 7.214 3.255 6.119-7.828ZM941.69 335.94l-10.165 26.854 23.096 7.22-12.931-34.074ZM908.41 389.213l23.115-26.42-24.588-23.463 1.473 49.883Z" />
                        <path d="m866.607 369.03 40.33-29.7 1.473 49.883-41.803-20.183Z" />
                        <path
                          d="m837.756 389.213 28.852-20.183 12.296 32.981-41.148-12.798ZM836.937 429.247l.818-40.034 41.148 12.798-41.966 27.236Z" />
                        <path
                          d="m895.623 418.964-16.72-16.953-41.966 27.235 58.686-10.282ZM895.623 418.964l12.787-29.751-29.507 12.798 16.72 16.953ZM908.41 389.213l-29.507 12.798-12.296-32.981 41.803 20.183Z" />
                        <path
                          d="m965.462 398.563-10.841-28.549-46.211 19.199 57.052 9.35ZM931.525 362.793l-23.115 26.419 46.211-19.199-23.096-7.22ZM939.066 436.795l-13.933-24.119 40.329-14.113-26.396 38.232ZM908.41 389.213l16.723 23.463 40.329-14.113-57.052-9.35Z" />
                        <path
                          d="m895.623 418.964 29.51-6.288-16.723-23.463-12.787 29.751ZM912.182 441.553l12.951-28.877-29.51 6.288 16.559 22.589ZM939.067 436.795l-26.884 4.758 12.95-28.877 13.934 24.119ZM913.986 450.907l11.638 1.803-11.638 8.207v-10.01ZM1016.39 426.512l8.96 16.628-11.86-1.667 2.9-14.961ZM1003.82 452.986l-21.201 7.931-4.591 12.522 25.792-20.453Z" />
                        <path
                          d="m967.755 466.222 14.864-5.305-4.591 12.522-10.273-7.217ZM1007.35 452.466l6.14-10.992 11.86 1.667-18 9.325ZM353.98 69.088l-13.936-17.062 31.969-1.369-18.033 18.431Z" />
                        <path d="m348.24 21.507-8.196 30.52 31.969-1.37-23.773-29.15Z" />
                        <path
                          d="m417.094 36.767-68.854-15.26 23.773 29.15 45.081-13.89ZM388.406 1.815 348.24 21.506l-1.473-16.574 41.639-3.117Z" />
                        <path d="m322.339 15.928 24.428-10.995 1.474 16.574-25.902-5.579Z" />
                        <path
                          d="m417.094 36.766-28.688-34.95-40.166 19.69 68.854 15.26ZM445.292 3.621l-56.886-1.806 28.689 34.951 28.197-33.145ZM551.029 247.527l-55.572 52.672 103.032 8.859-47.46-61.531ZM247.421 88.124l48.525-17.066-29.837-9.026-18.688 26.092ZM293.159 104.694l2.787-33.636 13.769 25.433-16.556 8.203Z" />
                        <path
                          d="m247.421 88.123 45.739 16.571 2.786-33.636-48.525 17.065ZM309.716 96.491l-13.77 20.674 15.738 1.147-1.968-21.82ZM247.421 88.124l16.229 37.9 29.51-21.329-45.739-16.571ZM236.929 101.742l26.72 24.282-16.229-37.9-10.491 13.618Z" />
                        <path
                          d="m227.092 148.34 36.557-22.316-26.72-24.283-9.837 46.599ZM215.123 73.847l21.806 27.894-45.575 6.892 23.769-34.786ZM203.813 44.97l11.311 28.877L232.174 43l-28.361 1.97ZM254.798 50.657 232.175 43l-17.051 30.847 39.674-23.19Z" />
                        <path d="M250.618 32.17 232.174 43l22.624 7.657-4.18-18.486Z" />
                        <path
                          d="m270.863 42.016-20.245-9.845 4.18 18.486 16.065-8.64ZM203.813 44.97l-40.657-6.893 2.787 35.77 37.87-28.877ZM191.354 108.634l35.738 39.706 9.837-46.599-45.575 6.893Z" />
                        <path
                          d="M165.943 73.847h49.181L203.813 44.97l-37.87 28.877ZM191.354 108.633l-25.411-34.786h49.181l-23.77 34.786ZM187.913 180.335l20.492-3.937-23.279-38.231 2.787 42.168Z" />
                        <path
                          d="m227.093 148.34-41.967-10.173 23.279 38.231 18.688-28.058ZM203.268 199.971l5.136-23.573-20.492 3.937 15.356 19.636ZM191.354 108.634l-6.228 29.533 41.967 10.173-35.739-39.706ZM163.157 38.078l-43.196-1.311 15.49 35.522 27.706-34.211ZM94.085 71.004l25.876-34.237 15.49 35.522-41.366-1.285ZM64.469 43.656l29.616 27.348 25.876-34.237-55.492 6.889Z" />
                        <path
                          d="m45.014 78.224 49.07-7.22L64.47 43.654 45.014 78.224ZM29.821 69.69l15.192 8.534 19.455-34.569L29.821 69.69Z" />
                        <path
                          d="M5.015 89.927 29.82 69.689l15.192 8.535L5.015 89.927ZM94.085 71.004l8.581 43.045 32.785-41.76-41.366-1.285ZM165.944 73.847l-30.493-1.558 27.706-34.212 2.787 35.77ZM149.551 119.299l16.393-45.452-30.493-1.558 14.1 47.01Z" />
                        <path
                          d="m82.09 146.862 20.576-32.814 39.919 31.339-60.494 1.475ZM102.255 177.381l-20.164-30.519 60.494-1.475-40.33 31.994Z" />
                        <path
                          d="m149.551 119.299-46.885-5.25 32.785-41.76 14.1 47.01ZM191.354 108.633l-41.803 10.665 16.392-45.451 25.411 34.786ZM185.125 138.167l-35.574-18.868 41.803-10.665-6.229 29.533Z" />
                        <path d="m142.584 145.387 6.967-26.089-46.885-5.25 39.918 31.339Z" />
                        <path
                          d="m185.125 138.167-42.541 7.22 6.967-26.088 35.574 18.868ZM187.912 180.335l-45.328-34.948 42.541-7.22 2.787 42.168Z" />
                        <path d="m144.632 195.925 43.28-15.591-45.328-34.947 2.048 50.538Z" />
                        <path
                          d="m102.255 177.381 42.377 18.544-2.048-50.538-40.329 31.994ZM118.24 222.833l26.392-26.907-42.377-18.544 15.985 45.451Z" />
                        <path d="m150.697 224.803-6.065-28.877-26.393 26.908 32.458 1.969Z" />
                        <path
                          d="m156.764 239.24-6.067-14.437-32.458-1.97 38.525 16.407ZM177.748 214.302l-27.051 10.501 6.068 14.437 20.983-24.938Z" />
                        <path d="m173.321 236.451-16.556 2.789 20.983-24.938-4.427 22.149Z" />
                        <path d="m177.748 254.118-4.427-17.667-16.556 2.789 20.983 14.878Z" />
                        <path d="m187.913 238.585-14.592-2.134 4.427 17.667 10.165-15.533Z" />
                        <path d="m193.103 261.447-15.355-7.329 10.164-15.533 5.191 22.862Z" />
                        <path d="m196.163 267.902-3.06-6.455-15.355-7.33 18.415 13.785Z" />
                        <path d="m207.419 263.308-14.315-1.861 3.059 6.456 11.256-4.595Z" />
                        <path d="m202.282 289.232-6.119-21.329 11.256-4.595-5.137 25.924Z" />
                        <path d="m240.974 291.202-33.555-27.894-5.137 25.924 38.692 1.97Z" />
                        <path
                          d="m236.165 251.93-28.746 11.378 33.555 27.894-4.809-39.272ZM191.463 314.502l10.819-25.27 48.991 30.655-59.81-5.385Z" />
                        <path
                          d="m231.108 364.435-39.645-49.934 59.81 5.385-20.165 44.549ZM291.192 278.786l-55.027-26.856 4.809 39.272 50.218-12.416Z" />
                        <path
                          d="m251.273 319.887-10.299-28.685-38.692-1.97 48.991 30.655ZM302.01 300.935l-10.819-22.149-50.217 12.416 61.036 9.733Z" />
                        <path
                          d="m251.273 319.887 50.737-18.952-61.036-9.733 10.299 28.685ZM348.16 318.656l-46.15-17.721-1.31 44.548 47.46-26.827Z" />
                        <path
                          d="M327.748 381.665 300.7 345.484l47.46-26.828-20.412 63.009ZM251.273 319.886l49.428 25.597 1.31-44.548-50.738 18.951Z" />
                        <path d="m231.108 364.435 69.593-18.951-49.428-25.597-20.165 44.548Z" />
                        <path d="m273.649 396.677 27.051-51.194 27.048 36.182-54.099 15.012Z" />
                        <path d="m231.108 364.435 42.542 32.242 27.051-51.194-69.593 18.952Z" />
                        <path d="m286.928 440.734-13.279-44.057 54.099-15.012-40.82 59.069Z" />
                        <path d="m237.256 434.825 36.394-38.148-42.542-32.241 6.148 70.389Z" />
                        <path
                          d="m286.927 440.734-49.671-5.909 36.393-38.148 13.278 44.057ZM245.616 469.776l41.311-29.042-49.671-5.909 8.36 34.951Z" />
                        <path d="m271.492 493.403-25.876-23.627 41.312-29.042-15.436 52.669Z" />
                        <path d="m268.622 501.224 2.87-7.821-25.876-23.628 23.006 31.449Z" />
                        <path
                          d="m285.454 502.538-13.962-9.136-2.87 7.821 16.832 1.315ZM204.388 207.901l-17.433 2.626 13.988 2.789 3.445-5.415Z" />
                        <path
                          d="m219.715 217.911-15.327-10.01-3.445 5.415 18.772 4.595ZM228.405 220.124l-8.69 5.334 8.69 1.314v-6.648ZM238.977 223.9l-10.572-3.776v6.648l10.572-2.872ZM206.108 225.295l6.119.983-4.423 1.861-1.696-2.844ZM242.788 224.925l6.026.247-3.525 2.789-2.501-3.036ZM179.553 29.873l24.261 11.54 4.591-17.664-28.852 6.125Z" />
                        <path
                          d="m229.824 27.248-21.42-3.499-4.591 17.663 26.011-14.164ZM222.829 13.795l-14.425 4.264 25.902 3.393-11.477-7.657ZM294.305 43l-18.197 12.962 23.77 6.729L294.305 43ZM311.684 36.082 294.306 43l5.573 19.691 11.805-26.609ZM283.978 25.935l27.705 10.147L294.305 43l-10.327-17.065Z" />
                        <path
                          d="M264.799 33.319 294.306 43l-10.328-17.065-19.179 7.384ZM299.879 2.635l11.805 11.16 35.083-12.47-46.888 1.31Z" />
                        <path d="m280.536 15.433 31.148-1.639-11.805-11.16-19.343 12.8Z" />
                        <path
                          d="m276.818 4.932 23.061-2.297-19.343 12.798-3.718-10.5ZM236.056 27.959l13.551-3.008-3.387 6.125-10.164-3.117Z" />
                        <path
                          d="m239.77 32.172-3.714-4.213 10.164 3.117-6.45 1.096ZM274.963 16.475h-8.855l9.073 6.343-.218-6.343ZM253.157 24.951h13.388l-10.219 4.65-3.169-4.65ZM246.711 17.075h12.623l-6.559 3.827-6.064-3.827ZM255.289 9.309l7.323.874-2.761 3.884-4.562-4.758ZM267.64 10.292v3.284l4.918-2.516-4.918-.768ZM232.174 11.934l8.305-1.642-4.424 4.65-3.881-3.008ZM261.63 18.332l-4.591 3.12h6.665l-2.074-3.12Z" />
                        <path d="m294.306 21.725-19.343-5.25.218 6.343 19.125-1.093Z" />
                    </g>

                    <g className="g-group">
                        <g>
                            <path
                              d="M800 243c-10.493 0-19 8.552-19 19.102 0 .851.154 1.884.377 2.935a28.395 28.395 0 0 0 3.417 8.655c4.35 7.29 15.215 25.308 15.215 25.308s10.676-17.744 14.841-24.672a31.711 31.711 0 0 0 3.567-8.49c.338-1.322.583-2.655.583-3.736 0-10.55-8.506-19.102-19-19.102Z"
                              fill="#D5FD00" />
                        </g>
                    </g>

                    <g className="g-group">
                        <g>
                            <path
                              d="M839 246c-10.493 0-19 8.552-19 19.102 0 .851.154 1.884.377 2.935a28.395 28.395 0 0 0 3.417 8.655c4.35 7.29 15.215 25.308 15.215 25.308s10.676-17.744 14.841-24.672a31.711 31.711 0 0 0 3.567-8.49c.338-1.322.583-2.655.583-3.736 0-10.55-8.506-19.102-19-19.102Z"
                              fill="#D5FD00" />
                        </g>
                    </g>

                    <g className="g-group">
                        <g>
                            <path
                              d="M878 195c-10.493 0-19 8.552-19 19.102 0 .851.154 1.884.377 2.935a28.395 28.395 0 0 0 3.417 8.655c4.35 7.29 15.215 25.308 15.215 25.308s10.676-17.744 14.841-24.672a31.711 31.711 0 0 0 3.567-8.49c.338-1.322.583-2.655.583-3.736 0-10.55-8.506-19.102-19-19.102Z"
                              fill="#D5FD00" />
                        </g>
                    </g>

                    <g className="g-group">
                        <g>
                            <path
                              d="M462 38c-10.493 0-19 8.552-19 19.102 0 .85.154 1.884.377 2.935a28.399 28.399 0 0 0 3.417 8.655C451.144 75.982 462.009 94 462.009 94s10.676-17.744 14.841-24.672a31.724 31.724 0 0 0 3.567-8.49c.338-1.322.583-2.656.583-3.736C481 46.552 472.494 38 462 38Z"
                              fill="#D5FD00" />
                        </g>
                    </g>

                    <g className="g-group">
                        <path
                          d="M116 166c-10.493 0-19 8.552-19 19.102 0 .851.154 1.884.377 2.935a28.403 28.403 0 0 0 3.417 8.655c4.35 7.29 15.215 25.308 15.215 25.308s10.676-17.744 14.841-24.672a31.711 31.711 0 0 0 3.567-8.49c.338-1.322.583-2.655.583-3.736 0-10.55-8.506-19.102-19-19.102Z"
                          fill="#D5FD00" />
                    </g>
                </svg>
            </div>

            {/* content */}
            <div className="makerlab-map-texts" data-show={showMapPins}>
                {mockData.map((data, index) => (
                  <div key={index} className="makerlab-map-text">
                      <h4>{data.title}</h4>
                      <p>{data.text}</p>
                  </div>
                ))}
            </div>
        </div>
    );
};

export default Map;