import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const CookieBar = ({ data, showCookieBanner, toggleCookieBannerState, toggleCookieConsent }) => {

  const handleCookieClick = () => {
    const cookieBanner = document.querySelector('.makerlab-cookie-wrapper');
    cookieBanner.style.display = 'none';
    localStorage.setItem('showCookieBanner', 'false');
    toggleCookieBannerState();
  }

  return (
    <div className='makerlab-cookie-wrapper' data-show={showCookieBanner}>
      <div className="makerlab-cookie-box">
        <div className="makerlab-cookie-text">
          <h4>Cookie Policies</h4>
          <div>{documentToReactComponents(data.fields.content)}</div>

          <div className='makerlab-cookie-buttons'>
            <button onClick={() => handleCookieClick()}>Decline</button>
            <button onClick={() => {
              handleCookieClick()
              toggleCookieConsent()
            }}>Accept</button>
          </div>
        </div>

        <svg className='makerlab-cookie-logo' fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 354 641">
          <g clipPath="url(#a)" fill="#000">
            <path d="m248.668 80.718-92.739 71.013-86.276-63.016V49.366l84.345 64.295 94.683-74.536v41.593h-.013Z" />
            <path
              d="M172.567 15.57c30.446-7.885 49.986-31.89 49.986-59.832 0-21.373-11.26-41.417-30.787-51.934v-34.185c37.729 14.14 62.23 48.325 62.23 87.435 0 51.283-45.682 92.7-94.67 92.7-57.926 0-98.306-43.398-98.306-91.71 0-53.576 41.705-94.342 94.342-94.342 5.63 0 10.919.001 17.205.991V15.57Zm-26.483-117.683c-31.115 2.632-53.62 29.91-53.62 59.167 0 29.258 22.177 55.883 53.62 58.516v-117.683ZM247.786 426.595H147.208c-34.069 0-53.205 13.965-53.205 41.581 0 29.207 16.549 41.906 52.561 41.906h101.222V543.1H143.32c-35.369 0-49.317 16.183-49.317 40.001 0 29.207 17.521 43.485 52.877 43.485h100.893v32.994H68.038v-30.474h19.465v-.639c-17.192-14.278-24.337-27.616-24.337-50.468 0-22.852 11.36-45.705 30.168-54.592-20.436-12.699-30.168-30.161-30.168-58.09 0-45.704 29.196-71.74 75.597-71.74h109.01v33.018h.013ZM247.85 209.645h-28.717v.665c22.039 12.936 33.4 36.139 33.4 62.339 0 53.727-40.746 97.5-94.166 97.5-59.1 0-100.514-42.119-100.514-96.172 0-26.863 10.022-49.415 31.051-63.667v-.665H62.851V175.16H247.85v34.485Zm-92.159-.652c-36.063 0-66.118 25.861-66.118 64.006 0 34.486 29.714 62.678 66.118 62.678 36.404 0 65.121-28.192 65.121-62.678 0-34.485-30.055-64.006-65.121-64.006Z" />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h354v641H0z" />
            </clipPath>
          </defs>
        </svg>

        {/*<svg className='makerlab-cookie-close' fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">*/}
        {/*  <path d="M1 24 24 1M1 1l23 23" stroke="#000" strokeWidth="2" strokeLinecap="round" />*/}
        {/*</svg>*/}
      </div>
    </div>
  );
};

export default CookieBar;